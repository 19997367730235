/*
 * Customized MaxFunding Navbar
 */
$(document).ready(function(){

    var $siteHeader = $(".site-header");
    var $headerToggle = $(".site-header .header-toggle");
    var $headerNav = $(".header-nav");
    var $window = $(window), $html = $('html');

    // Add class 'active' on burger open
    $headerToggle.click(function(e){
        var toggle = $(this);
        if(toggle.hasClass('active')){
            $siteHeader.removeClass('active');
            $headerToggle.removeClass('active');
            $headerNav.removeClass('active');
            $('body').removeClass('lock');
        }else{
            $siteHeader.addClass('active');
            $headerToggle.addClass('active');
            $headerNav.addClass('active');
            $('body').addClass('lock');
        }
        e.preventDefault();
    });
    
    // Add Header Class 'sticky'
    AdjustHeader();
    $window.scroll(function() { AdjustHeader(); });
    function AdjustHeader(){
        var winWidth = window.innerWidth || $window.width();
        scrollTop = 1;
        if ($window.scrollTop() > scrollTop) {
            if (!$siteHeader.hasClass("sticky")) {
                return $siteHeader.addClass("sticky");
            }
        } else {
            $siteHeader.removeClass("sticky");
        }
    }
    
    // Hide Header on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $siteHeader.outerHeight();
    $(window).scroll(function(event){
        didScroll = true;
    });
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 50);
    function hasScrolled() {
        var st = $(this).scrollTop();
        if(Math.abs(lastScrollTop - st) <= delta)
            return;
        if (st > lastScrollTop && st > navbarHeight){
            $siteHeader.addClass('scrolling');
        } else {
            if(st + $(window).height() < $(document).height()) {
                $siteHeader.removeClass('scrolling');
            }
        }
        lastScrollTop = st;
    }

    //Page scroll to on load
    var hash = window.location.hash;
    var headerHeight = $siteHeader.height();
    if (hash) {
        var target = $(window.location.hash);
        if (target.length) {
            $('html, body').animate({
                scrollTop: (target.offset().top - headerHeight)
            }, 500, 'swing', function() {
                setTimeout(function(){
                    $siteHeader.removeClass('scrolling');
                }, 100);
            });
            history.replaceState(null, null, ' ');
        }
    }

    // Page scroll on anchor click
    $headerNav.find('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - headerHeight)
                }, 500, 'swing', function() {
                    setTimeout(function(){
                        $siteHeader.removeClass('scrolling');
                    }, 100);
                });
                $headerToggle.trigger('click');
                return false;
            }
        }
    });

    // Project player
    var index = 0,
    audioFlag = false,
    interval = null;

    var audio = new Audio(),
    iconPlay = '<i class="fal fa-play"></i>',
    iconPause = '<i class="fal fa-pause"></i>',
    iconInfo = '<i class="fal fa-wifi-exclamation"></i>',
    projectPlayer = $('.project-player'),
    projectItem = $('.project-container .project-item'),
    playListToggle = $('.player-list-toggle'),
    playList = $('.player-list'),
    playerBg = projectPlayer.find('.player-bg'),
    playerThumb = projectPlayer.find('.player-thumb'),
    playerTitle = projectPlayer.find('.player-title'),
    playerArtist = projectPlayer.find('.player-artist'),
    playerSingle = $('.player-single');

    var $play = playerSingle.find('.player-play'),
    $track = playerSingle.find('.player-track'),
    $progress = playerSingle.find('.player-progress'),
    $handle = playerSingle.find('.player-handle'),
    $time = playerSingle.find('.player-played'),
    $duration = playerSingle.find('.player-duration');

    // Create playlist
    var listHtml = '',
    itemLen = projectItem.length;
    projectItem.each(function(index){
        var title = $(this).data("title");
        var artist = $(this).data("artist");
        if(index == 0) { listHtml = '<ul>'; }
        listHtml = listHtml + '<li><a data-item="'+index+'" href="javascript:;">' + title + ' | ' +  artist + '</a></li>';
        if (index === itemLen - 1) {
            listHtml = listHtml + '</ul>';
            playList.html(listHtml);
        }
    });

    // Playlist toggle
    playListToggle.click(function(){
        var icon = playListToggle.find('i');
        playList.toggleClass('active');
        $(this).toggleClass('active');
        if(playList.hasClass('active')){
            icon.removeClass('fa fa-list-music').addClass('fa fa-remove');
        }else{
            icon.removeClass('fa fa-remove').addClass('fa fa-list-music');
        }
    });

    playList.find('a').click(function(){
        var index = $(this).data('item');
        setMusic(index, true);
    });

    // Next / prev project items
    $(".project-next, .project-prev, .player-prev, .player-next").click(function (e) {
        const list = [...document.querySelectorAll('.project-container .project-item')];
        const active = document.querySelector('.project-container .project-item.active');
        index = list.indexOf(active);
        if($(this).is('.project-next, .player-next')){
            index = (index + 1 < projectItem.length) ? index + 1 : 0;
        }else{
            index = (index > 0) ? index - 1 : projectItem.length - 1;
        }
        setMusic(index, true);
    });

    // Set audio file on next / prev
    var currentIndex = $('.project-container .project-item.active').index();
    if(currentIndex > 0){
        setMusic(currentIndex, false);
    }else{
        setMusic(0, false);
    }
    function setMusic(index, play){
        var item = projectItem.eq(index);

        projectItem.removeClass('active');
        projectItem.eq(index).addClass('active');

        var icon = playListToggle.find('i');
        icon.removeClass('fa fa-remove').addClass('fa fa-list-music');
        playList.find('a').removeClass('active');
        playList.find('a[data-item="'+index+'"]').addClass('active');
        playListToggle.removeClass('active');
        playList.removeClass('active');

        var img = new Image();
        const src = item.data('image');
		jQuery(img).attr("src", src).on("load", function(){
            playerThumb.attr('src', src);
            playerBg.attr('style','background-image: url('+src+')');
        });
        
        $(audio).attr('src', item.data('music'));
        playerTitle.html(item.data('title'));
        playerArtist.html(item.data('artist'));

        // var newURL = base_url() + 'media/' + convertToSlug(item.data('title')) + '/';
        // window.history.replaceState('', '', newURL);

        $duration.text('0:00');
        $time.text('0:00');
        $progress.removeAttr('style');
        $handle.removeAttr('style');

        audio.onerror = function() {
            audioFlag = true;
            $play.html(iconInfo);
            return false;
        };
        audioFlag = false;

        $(audio).on('loadedmetadata', function() {
            $duration.text(calculateTime(audio.duration));
        });

        if(play) {
            $play.trigger('click');
        }
    }
    
    // Play button clicked
    $play.on("click", playPause);

    // Play pause audio file
    function playPause(){
        if(audioFlag){ return false; }
        if (audio.paused){
            audio.play();
            $play.html(iconPause);
            interval = setInterval(barState, 50);
        }else{
            audio.pause();
            $play.html(iconPlay);
            clearInterval(interval);
        };
    };

    //Progress bar
    function barState(){
        if(audioFlag){ return false; }
        if (!audio.ended){
            var totalTime = parseInt(audio.currentTime / audio.duration * 100);
            $progress.css({"width": totalTime + 1 + "%"});
            $handle.css('left', totalTime + 1 + '%');
            $time.text(calculateTime(audio.currentTime));
        }else if (audio.ended){
            $play.html(iconPlay);
            $time.text('0:00');
            $progress.removeAttr('style');
            $handle.removeAttr('style');
            clearInterval(interval);
            $(".player-next").trigger('click');
        };
    };

    //Event track trigger
    $track.on('click', function(e) {
        if(audioFlag){ return false; }

        var self = $(this),
        totalWidth = self.width(),
        offsetX = e.offsetX,
        offsetPercentage = offsetX / totalWidth;
        
        audio.currentTime = audio.duration * offsetPercentage;
        $time.text(calculateTime(audio.currentTime));
        
        $progress.css('width', offsetPercentage * 100 + '%');
        $handle.css('left', offsetPercentage * 100 + '%');
    });

    function calculateTime(seconds) {
        seconds = Math.floor(seconds);
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return mins + ":" + (secs < 10 ? "0": "") + secs;
    }
    function convertToSlug(Text) {
        return Text.toLowerCase()
                   .replace(/[^\w ]+/g, '')
                   .replace(/ +/g, '-');
    }
    function base_url() {
        var pathparts = location.pathname.split('/');
        if (location.host == 'localhost') {
            var url = location.origin+'/'+pathparts[1].trim('/')+'/';
        }else{
            var url = location.origin;
        }
        return url;
    }


    // File input
    $(document).on('change', 'input[type="file"]', function(e) {
        var result = $(this).closest('div').find('.file-result');
        if(e.target.files.length){
            result.html(e.target.files[0].name);
        }else{
            result.html('Keine Dateien ausgewählt');
        }
    });

    $.validator.addMethod('filesize', function (value, element, param) {
        return this.optional(element) || (element.files[0].size <= param)
    }, 'File size must be less than {0}');
    
    $.validator.addMethod("alpha", function(value, element, param) {
        return this.optional(element) || /^[a-zA-Z ]*$/.test( value );
    }, "alpha only");

    $.validator.addMethod("numero", function(value, element, param) {
        return this.optional(element) || /^[+0-9 ]*$/.test( value );
    }, "numbers only");

    $('#form-contact').validate({
        rules: {
            name: {
                required: true,
                alpha: true
            },
            email: {
                email: true,
                required: true
            },
            phone: {
                required: true,
                minlength: 6,
                numero: true
            },
            subject: {
                required: true
            },
            /*fs_file: {
                required: true,
                filesize: (20 * 1024 * 1024),
                accept: "audio/*,image/*"
            },*/
        },
        highlight: function(element) {
            $(element).closest('.form-group').addClass('has-error');
        },
        unhighlight: function(element) {
            $(element).closest('.form-group').removeClass('has-error');
        },
        errorElement: 'span',
        errorClass: 'help-block',
        errorPlacement: function(error, element) {},
        submitHandler: function (form) {
            $(form).find('button[type="submit"]').prop("disabled", true);
            var formData = new FormData(form);
            $.ajax({
                url: form.action,
                type: form.method,
                data: formData,
                dataType: 'json',
                processData: false,
                contentType: false,
                cache: false,
                success: function(response) {
                    var message = "";
                    if(response.status == true){
                        message = '<div role="alert" class="alert alert-success">'+response.message+'</div>';
                        form.reset();
                    }else{
                        message = '<div role="alert" class="alert alert-danger">'+response.message+'</div>';
                    }
                    $('.form-contact-result').html(message);
                    $(form).find('button[type="submit"]').prop("disabled", false);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status == 500) {
                        message = '<div role="alert" class="alert alert-danger">'+jqXHR.responseText+'</div>';
                    } else {
                        message = '<div role="alert" class="alert alert-danger">Unexpected error.</div>';
                    }
                    $(form).find('button[type="submit"]').prop("disabled", false);
                }
            });
            return false;
        }
    });

    // Audio samples
    var settings_ap = {
        disable_volume: 'on',
        disable_scrub: 'default',
        design_skin: 'skin-wave',
        skinwave_dynamicwaves:'on',
        skinwave_enableSpectrum: "off",
        settings_backup_type:'full',
        settings_useflashplayer:'auto',
        skinwave_spectrummultiplier: '4',
        design_color_bg: 'dddddd',
        design_wave_color_progress: 'baaf87',
        skinwave_comments_enable:'off',
        skinwave_mode: 'small',
        autoplay: 'off',
        embed_code:"",
        settings_extrahtml:"",
        skinwave_wave_mode: 'canvas',
        skinwave_wave_mode_canvas_waves_number: 180,
        skinwave_wave_mode_canvas_waves_padding: 1,
        skinwave_wave_mode_canvas_reflection_size: 0.50,
        design_animateplaypause: "off"
    };
    $('.ap-js').each(function(){
        var player_id = $(this).attr('id');
        dzsap_init("#"+player_id,settings_ap);
    });

    // Reviews slider 
    var swiperReviews = new Swiper(".swiper-reviews", {
        loop: true,
        effect: 'slide',
        slidesPerView: 1,
        autoplay: false,
        pagination: false,
        autoHeight: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    // Websites slider 
    /*var swiperWeb = new Swiper(".swiper-web", {
        loop: true,
        effect: 'fade',
        slidesPerView: 1,
        autoplay: false,
        pagination: false,
        autoHeight: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });*/

    // Cookie consent 
    var cookieName = 'cookie-consent';
    var cookieLifetime = 365;
    if (_shouldShowPopup()) {
        $('.cookie-consent').removeClass('d-none');
    }
    $('.cookie-accept, .cookie-decline').on('click', function () {
        _setCookie(cookieName, 1, cookieLifetime);
        $('.cookie-consent').addClass('d-none');
    });
    function _setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    function _getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    function _shouldShowPopup() {
        if (_getCookie(cookieName)) {
            return false;
        } else {
            return true;
        }
    }
});